import { CDN_DOMAIN } from "../config/cdn"

export default [
    {
        logo: `${CDN_DOMAIN}/static/logos/deliveroo.png`,
        name: "Deliveroo"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/bulot_bulot.png`,
        name: "Bulot Bulot"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/keep_cool.png`,
        name: "Keep Cool"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/magna.png`,
        name: "Magna"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/rituals.png`,
        name: "Rituals Cosmetics"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/indiana.png`,
        name: "Indiana Café"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/pere_et_fish.png`,
        name: "Père & Fish"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/adopt.png`,
        name: "Adopt"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/pepper_grill.png`,
        name: "Pepper Grill"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/carglass.png`,
        name: "Carglass"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/brewdog.png`,
        name: "Brewdog"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/ldlc.png`,
        name: "LDLC"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/zapa.png`,
        name: "ZAPA"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/les_petits_chaperons_rouges.png`,
        name: "Les Petits Chaperons Rouges"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/vertical_art.png`,
        name: "Vertical'Art"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/schmidt.png`,
        name: "Schmidt"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/l_orange_bleue.png`,
        name: "L'Orange bleue"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/carrefour_city.png`,
        name: "Carrefour City"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/maison_et_services.png`,
        name: "Maison & Services"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/dominos.png`,
        name: "Domino's"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/la_croissanterie.png`,
        name: "La Croissanterie"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/intercaves.png`,
        name: "Intercaves"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/bill_the_butcher.png`,
        name: "Bill the butcher"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/fitness_park.png`,
        name: "Fitness Park"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/cash_converters.png`,
        name: "Cash Converters"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/nicolas.png`,
        name: "Nicolas"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/gigafit.png`,
        name: "Gigafit"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/dubble.png`,
        name: "Dubble"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/bastide.png`,
        name: "Bastide le confort médical"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/feu_vert.png`,
        name: "Feu vert"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/casino_proximites.png`,
        name: "Casino Proximités"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/esprit.png`,
        name: "Esprit"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/alain_afflelou.png`,
        name: "Alain Afflelou"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/gautier.png`,
        name: "Gautier"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/intermarche.png`,
        name: "Intermarché"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/bouygues_telecom.png`,
        name: "Bouygues Telecom"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/exki.png`,
        name: "Exki"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/carrefour.png`,
        name: "Carrefour"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/ankka.png`,
        name: "Ankka"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/gaston.png`,
        name: "Gaston"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/bureau_vallee.png`,
        name: "Bureau Vallée"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/emergence_concepts.png`,
        name: "Emergence Concepts"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/naf_naf.png`,
        name: "NAF NAF"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/bchef.png`,
        name: "Bchef"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/nous_anti_gaspi.png`,
        name: "Nous anti-gaspi"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/dorcel.png`,
        name: "Dorcel"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/leon_de_bruxelles.png`,
        name: "Léon de Bruxelles"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/naturalia.png`,
        name: "Naturalia"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/otacos.png`,
        name: "O'Tacos"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/mobalpa.png`,
        name: "Mobalpa"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/subway.png`,
        name: "Subway"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/doc_biker.png`,
        name: "Doc'Biker"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/boulangerie_louise.png`,
        name: "Boulangerie Louise"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/mongoo.png`,
        name: "Mongoo"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/la_boucherie.png`,
        name: "La boucherie"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/warpzone.png`,
        name: "Warpzone"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/joyeux.png`,
        name: "Joyeux"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/on_air.png`,
        name: "ON AIR"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/animalis.png`,
        name: "Animalis"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/vapostore.png`,
        name: "Vapostore"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/la_vie_claire.png`,
        name: "La Vie Claire"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/arkose.png`,
        name: "Arkose"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/castorama.png`,
        name: "Castorama"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/neoness.png`,
        name: "Neoness"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/groupe_le_duff.png`,
        name: "Groupe Le Duff"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/la_cervoiserie.png`,
        name: "La Cervoiserie"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/pizza_time.png`,
        name: "Pizza Time"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/big_fernand.png`,
        name: "Big Fernand"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/carls_junior.png`,
        name: "Carl's Junior"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/agem.png`,
        name: "AGEM Votre intérieur sur mesure"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/feuillette.png`,
        name: "Feuillette"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/brothers_crepes_and_cafe.png`,
        name: "Brother's Crêpes and Café"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/amrest.png`,
        name: "Amrest"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/atol.png`,
        name: "Atol Mon Opticien"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/guinot_mary_cohr.png`,
        name: "Guinot Mary Cohr"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/auchan.png`,
        name: "Auchan"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/les_boites_a_meuh.png`,
        name: "Les boites à Meuh"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/midas.png`,
        name: "Midas"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/homer_lobster.png`,
        name: "Homer Lobster"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/takko_fashion.png`,
        name: "Takko Fashion"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/eat_salad.png`,
        name: "Eat Salad"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/tacoshake.png`,
        name: "Tacoshake"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/basilic_and_co.png`,
        name: "Basilic & co"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/fresh.png`,
        name: "Fresh. au service du goût"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/boggi_milano.png`,
        name: "Boggi Milano"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/vins_et_bieres.png`,
        name: "V&B Vins et Bières"
    },
    /*
    {
        logo: `${CDN_DOMAIN}/static/logos/cojean.png`,
        name: "Cojean"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos/haagen_dazs.png`,
        name: "Häagen-Dazs"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/fbh.png`,
        name: "FBH"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/pitaya.png`,
        name: "Pitaya"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/stephane_plaza_immobilier.png`,
        name: "Stéphane Plaza Immobilier"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/comtesse_du_barry.png`,
        name: "Comtesse du Barry"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/basic_fit.png`,
        name: "Basic-fit"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/shurgard.png`,
        name: "Shurgard"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/five_pizza_original.png`,
        name: "Five Pizza Original"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/relooking_beaute_minceur.png`,
        name: "Relooking Beauté Minceur"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/bebe9.png`,
        name: "Bébé 9"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/addict_paris.png`,
        name: "Addict Paris"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/netquattro_sports.png`,
        name: "Netquattro Sports"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/kfc.png`,
        name: "KFC"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/reaute_chocolat.png`,
        name: "Réauté Chocolat"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/grand_optical.png`,
        name: "Grand Optical"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/speed_burger.png`,
        name: "Speed Burger"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/tigellabella.png`,
        name: "Tigellabella"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/picard.png`,
        name: "Picard"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/gagao.png`,
        name: "Gagao"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/anticafe.png`,
        name: "Anticafé"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/generale_optique.png`,
        name: "Générale d'Optique"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/homebox.png`,
        name: "Homebox"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/day_by_day.png`,
        name: "day by day - Mon épicerie en vrac"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/carrefour_proximite.png`,
        name: "Carrefour Proximité"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/volupte.png`,
        name: "Volupté Anytime"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/fitness_boutique.png`,
        name: "Fitness Boutique"
    } /*
    {
        logo: `${CDN_DOMAIN}/static/logos/pascal_coste.png`,
        name: "Pascal Coste"
    },*/,
    {
        logo: `${CDN_DOMAIN}/static/logos/gamecash.png`,
        name: "Gamecash"
    } /*
    {
        logo: `${CDN_DOMAIN}/static/logos/gemo.png`,
        name: "Gémo"
    },*/,
    {
        logo: `${CDN_DOMAIN}/static/logos/ada.png`,
        name: "ADA"
    } /*,
    {
        logo: `${CDN_DOMAIN}/static/logos/waffle_factory.png`,
        name: "Waffle Factory"
    }*/,
    {
        logo: `${CDN_DOMAIN}/static/logos/body_minute.png`,
        name: "Body Minute"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/autour_de_bebe.png`,
        name: "Autour de bébé"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/audika.png`,
        name: "Audika"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/231_east.png`,
        name: "231 East"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/750g_la_table.png`,
        name: "750g La table"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/ald_automotive.png`,
        name: "ALD Automotive"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/all4_home.png`,
        name: "All4 Home"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/amorino.png`,
        name: "Amorino"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/astikoto.png`,
        name: "Astikoto"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/auchan.png`,
        name: "Auchan"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/augustin.png`,
        name: "Augustin"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/axe_reseaux.png`,
        name: "Axe Réseaux"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/bam.png`,
        name: "BAM Karaoke Box"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/banh_me_tender.png`,
        name: "Banh Me Tender"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/birdys.png`,
        name: "Birdy's"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/canas_y_tapas.png`,
        name: "Cañas y Tapas"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/carador.png`,
        name: "Carador"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/carlance.png`,
        name: "Carlance"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/carrement_fleurs.jpg`,
        name: "Carrément Fleurs"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/casino_restauration.png`,
        name: "Casino Restauration"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/cavavin.png`,
        name: "Cavavin"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/cheesers.jpg`,
        name: "Cheesers"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/damart.png`,
        name: "Damart"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/ds_cafe.png`,
        name: "DS Café"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/emilie_and_the_cool_kid.png`,
        name: "Emilie and the cool kid"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/firmin.png`,
        name: "Firmin"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/france_loisirs.png`,
        name: "France loisirs"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/irrijardin.png`,
        name: "Irrijardin"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/jeux_video_and_co.png`,
        name: "Jeux vidéo and co"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/jour.png`,
        name: "Jour"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/kiwill.png`,
        name: "Kiwill"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/krys.png`,
        name: "Krys"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/la_frituur.png`,
        name: "La Frituur"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/la_piadineria.png`,
        name: "La Piadineria"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/ladysushi.png`,
        name: "Ladysushi"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/le_falaf.png`,
        name: "Le Falaf"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/le_roi_du_matelas.png`,
        name: "Le roi du matelas"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/les_burgers_de_papa.png`,
        name: "Les burgers de papa"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/les_comptoirs_de_la_bio.png`,
        name: "Les comptoirs de la bio"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/les_petits_bilingues.png`,
        name: "Les petits bilingues"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/les_resistants.png`,
        name: "Les résistants"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/levrette_cafe.png`,
        name: "Levrette café"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/logis.png`,
        name: "Logis"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/louise.png`,
        name: "Louise"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/ma_parenthese.png`,
        name: "Ma parenthèse"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/maison_gazelle.jpg`,
        name: "Maison gazelle"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/medor_et_compagnie.png`,
        name: "Médor et compagnie"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/merguez_corner.png`,
        name: "Merguez corner"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/monde_virtuel.png`,
        name: "Monde virtuel"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/monoprix.png`,
        name: "Monoprix"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/mr_bricolage.png`,
        name: "Mr. Bricolage"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/ninkasi.png`,
        name: "Ninkasi"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/optic_2000.png`,
        name: "Optic 2000"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/persille.png`,
        name: "Persillé"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/pizza_cosy.png`,
        name: "Pizza Cosy"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/pizza_hut.png`,
        name: "Pizza Hut"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/progressium.png`,
        name: "Progressium"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/rapid_pare_brise.png`,
        name: "Rapid Pare-Brise"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/reaute_chocolat.png`,
        name: "Réauté Chocolat"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/rexel.png`,
        name: "Rexel"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/rodizio_brazil.png`,
        name: "Rodizio Brazil"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/roule.png`,
        name: "Roulé"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/size_factory.png`,
        name: "Size-Factory"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/sophie_lebreuilly.jpg`,
        name: "Sophie Lebreuilly"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/stella_forest.png`,
        name: "Stella Forest"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/tajinier.png`,
        name: "Tajinier"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/thalgo.png`,
        name: "Thalgo"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/truffaut.png`,
        name: "Truffaut"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/wefit.png`,
        name: "Wefit.club"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos/yumi.png`,
        name: "Yumi"
    }
]
